<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='crc m-auto'>
        <div class='flex mt-8 text-gray-700'>
          <router-link class='flex'
            :to='{ name: "settings" }'>
            <i class='icon'>arrow_back</i>
          </router-link>
          <div class='ml-16 text-lg font-bold'>{{ $t('pages.currencies.d6d7addf') }}</div>
        </div>
        <skeleton-loader-component class='mt-24'
          :loading='!supportedCurrencies'>
          <card-element class='p-16'>
            <div class='flex flex-wrap gap-8'>
              <div class='tcm p-8 rounded cursor-pointer'
                v-for='currency in supportedCurrencies'
                :class='{ "active": currency.code === userCurrency }'
                :key='currency.code'
                @click='changeCurrency(currency.code)'>
                <div>{{ currency.code }}</div>
                <div class='cyc text-sm'>{{ currency.name }}</div>
              </div>
            </div>
          </card-element>
        </skeleton-loader-component>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import SkeletonLoader from '@/components/skeleton-loader'
  import TopNavigation from '@/components/top-navigation'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'skeleton-loader-component': SkeletonLoader,
      'top-navigation-component': TopNavigation
    },

    computed: {
      userCurrency () {
        return this.$store.state.app.currency
      },

      supportedCurrencies () {
        return this.$store.state.settings.supportedCurrencies
      }
    },

    methods: {
      changeCurrency (currency) {
        localStorage.setItem('currency', currency)
        this.$store.dispatch('app/changeCurrency', { currency })
      }
    }
  }
</script>

<style lang='scss' scoped>
  .crc {
    max-width: 480px;
  }

  .tcm {
    width: calc(50% - 8px);

    @apply
      border-transparent
      border-2;

    &:hover {
      @apply border-primary;
    }

    &.active {
      @apply
        text-white
        bg-primary;
    }
  }

  .cyc {
    @apply text-gray-500;

    .tcm.active & {
      @apply text-white;
    }
  }
</style>
